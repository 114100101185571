<template>
    <v-main style="min-height: 80% !important">
        <v-container class="pb-12">
            <v-row>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="text-left mb-3"
                        :class="$mq != 'lg' ? 'mt-3' : 'mt-6'"
                    >
                        Textos do E-Commerce
                    </h2>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        v-model="parameters.store.b2dsobre"
                        name="about"
                        filled
                        label="Sobre a empresa"
                        :color="parameters.primaryColor"
                    ></v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        v-model="parameters.store.b2ddescri"
                        name="Description"
                        filled
                        label="Descrição da empresa"
                        :color="parameters.primaryColor"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="pt-0">
                    <v-btn elevation="0" :color="parameters.primaryColor" text @click="$router.push('/home')">Cancelar</v-btn>
                </v-col>
                <v-col cols="6" class="pt-0">
                    <v-btn elevation="0" dark :color="parameters.secondaryColor" @click="save">Salvar</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods:{
        save(){
            this.$store.dispatch("parameters/saveb2d");
        }
    }
};
</script>

<style>
</style>